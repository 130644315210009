.success {
  cursor: pointer;
  font-size: 36px;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  user-select: none;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform opacity;
}
/*# sourceMappingURL=src/components/Success.css.map */