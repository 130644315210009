

	.next
		border-radius: 25px
		font-size 12px
		border 2px solid #fff
		display inline-block
		padding 10px 20px
		cursor pointer
		user-select none

